import React from "react";

import Hero from "../components/Hero";
import heroimg from "../Assets/images/hero.png";
import Frame1 from "../Assets/images/Frame.svg";
import Frame2 from "../Assets/images/frame2.svg";
import Frame3 from "../Assets/images/frame3.svg";

import blogImg1 from "../Assets/images/blogImage1.png";
import blogImg2 from "../Assets/images/blogImage2.png";
import blogImg3 from "../Assets/images/blogImage3.png";
import FreeGuideImg from "../Assets/images/FreeGuideImg.png";

import Introduction from "../components/Introduction";

import Blog from "../components/Blog";
import Footer from "../components/Footer";
import FreeGuide from "../components/FreeGuide";
import CTA from "../components/CTA";
import CTAImage from "../Assets/images/CTAImage.png";
import Video from "../components/ReviewVideo/Video";
import JsonVideo from "../Data/JsonData.json";
import Testimonials from "../components/Testimonials/Testimonials";
import ReviewVideo from "../components/ReviewVideo/ReviewVideo";
import CoursesCard from "../components/HomeCourses/CoursesCard";
import about1 from "../Assets/images/about_01.jpg";
import hero3 from "../Assets/images/hero2.jpg";

const Home = () => {
  const heroData = [
    {
      title: "Transform Your Coding Journey With TakeChange",
      description:
        "This online programming language class, 'TakeChange,' is designed for beginners and aspiring programmers who want to dive into the world of coding using one of the most versatile and popular languages in the industry.",
      img: about1,
    },
  ];
  const introdata = [
    {
      title: "I can help you in these particular areas.",
      des1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua minim veniam.",
      des2: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. ",
    },
  ];

  const blogData = [
    {
      img: blogImg1,
      title: "Balancing your love and work life.",
      des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua minim veniam.",
    },
    {
      img: blogImg2,
      title: "A short break from Social Media is important.",
      des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua minim veniam.",
    },
    {
      img: blogImg3,
      title: "How to be 1% Better Every Day",
      des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua minim veniam.",
    },
  ];
  const FreeGuideData = [
    {
      title:
        "Don’t miss out on my ‘Live life at the full potential’ free guide",
      des1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua minim veniam.",
      des2: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. ",
      img: FreeGuideImg,
    },
  ];
  const CTAData = [
    {
      title: "Get 15-Minutes Complimentary online session.",
      des: "Limited Period Offer. Claim Now.",
      img: CTAImage,
    },
  ];
  return (
    <>
      {heroData.map((val) => {
        return (
          <>
            <Hero title={val.title} des={val.description} img={val.img} />
          </>
        );
      })}
      {/* {introdata.map((val) => {
        return (
          <>
            <Introduction title={val.title} des1={val.des1} des2={val.des2} />
          </>
        );
      })} */}
      <CoursesCard />
      <Testimonials />
      <ReviewVideo />

      {/* <section className="mt-4 mb-4">
        <div className="container mt-4 mb-4 ">
          <h2 className="text-center fw-bold" style={{ color: "#333461" }}>
            Stay Motivated, read the
            <br /> weekly blog articles.
          </h2>
          <div className="row">
            {blogData.map((val) => {
              return (
                <>
                  <div className="col-md-4">
                    <Blog img={val.img} title={val.title} des={val.des} />
                  </div>
                </>
              );
            })}
          </div>
          <div className="text-center mt-3">
            <button
              className="btn text-white w-25"
              style={{ backgroundColor: "#20AD96" }}
            >
              Read More Blogs
            </button>
          </div>
        </div>
      </section>
      {FreeGuideData.map((val) => {
        return (
          <>
            <FreeGuide
              title={val.title}
              des1={val.des1}
              des2={val.des2}
              img={val.img}
            />
          </>
        );
      })}
      {CTAData.map((val) => {
        return (
          <>
            <CTA title={val.title} des={val.des} img={val.img} />
          </>
        );
      })} */}
    </>
  );
};

export default Home;
