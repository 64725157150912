import React from "react";
import CoursesDetails from "../components/Courses/CoursesDetails";
import CoursesCard from "../components/HomeCourses/CoursesCard";

const Courses = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="var(--blue)"
          fill-opacity="0.3"
          d="M0,256L48,261.3C96,267,192,277,288,261.3C384,245,480,203,576,197.3C672,192,768,224,864,213.3C960,203,1056,149,1152,154.7C1248,160,1344,224,1392,256L1440,288L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>
      <div>
        <CoursesCard />
      </div>
    </>
  );
};

export default Courses;
