import React from "react";
import Header from "../components/Navbar/Header";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
const Layout = () => {
  return (
    <>
      <Header />
      <div style={{ marginTop: "70px" }} className=" ">
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default Layout;
