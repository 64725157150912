import React from "react";
import IntroCards from "../../components/HomeCourses/IntroCards";
import python from "../../Assets/images/python.png";
import java from "../../Assets/images/Java.png";
import php from "../../Assets/images/php.png";

const CoursesCard = () => {
  const introCard = [
    {
      id: "1",
      icon: python,
      title: "Python FullStack",
      list: [
        "HTML & CSS3 Syllabus",
        "Bootstrap Syllabus",
        "Javascript Syllabus",
        "PostgreSQL Syllabus",
        "Vue JSSyllabus",
        "Django Syllabus",
      ],
    },
    {
      id: "2",
      icon: java,
      title: "Java FullStack",
      list: [
        "JAVA Full Stack",
        "HTML & CSS",
        "Javascript",
        "Web architecture",
        "HTTP and REST",
        "Spring MVC Testing",
      ],
    },
    {
      id: "3",
      icon: php,
      title: "PHP FullStack",
      list: ["HTML & CSS", "Javascript", "PHP", "Database"],
    },
    {
      id: "4",
      icon: java,
      title: "Java FullStack",
      list: [
        "JAVA Full Stack",
        "HTML & CSS",
        "Javascript",
        "Web architecture",
        "HTTP and REST",
        "Spring MVC Testing",
      ],
    },
  ];

  return (
    <>
      <div>
        <div className="container">
          <h1 className="text-center mt-5" style={{ color: "var(--orange) " }}>
            Our Premium Courses
          </h1>

          <div className="row mt-3  ">
            {introCard.map((val) => {
              return (
                <>
                  <div
                    key={val.id}
                    className="col-md-6 col-sm-12 col-lg-3 mb-3"
                  >
                    <IntroCards
                      id={val.id}
                      title={val.title}
                      list={val.list}
                      icon={val.icon}
                    />
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CoursesCard;
