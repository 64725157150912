import React from "react";

const Video = ({ video }) => {
  return (
    <>
      <div className="card mb-4 mx-2">
        <video src={video} className="w-100" controls></video>
      </div>
    </>
  );
};

export default Video;
