import React from "react";
import starIcon from "../../Assets/images/Staricon.png";
import ReviewImg1 from "../../Assets/images/image74.jpg";
import ReviewImg2 from "../../Assets/images/Image.jpg";
import ReviewImg3 from "../../Assets/images/image72.jpg";
import Reviews from "./Reviews";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Testimonials = () => {
  const ReviewData = [
    {
      img: ReviewImg1,
      des: '"I gained so much confidence in my ability to connect and deepen my relationships with people. "',
      name: "Jane",
      icon: starIcon,
    },
    {
      img: ReviewImg2,
      des: '"I gained so much confidence in my ability to connect and deepen my relationships with people. "',
      name: "Catherine",
      icon: starIcon,
    },
    {
      img: ReviewImg1,
      des: '"I gained so much confidence in my ability to connect and deepen my relationships with people. "',
      name: "Jane",
      icon: starIcon,
    },
    {
      img: ReviewImg1,
      des: '"I gained so much confidence in my ability to connect and deepen my relationships with people. "',
      name: "Jane",
      icon: starIcon,
    },
    {
      img: ReviewImg3,
      des: '"I gained so much confidence in my ability to connect and deepen my relationships with people. "',
      name: "Jane",
      icon: starIcon,
    },
  ];
  const options = {
    loop: true,
    center: true,
    items: 3,
    margin: 0,
    autoplay: true,
    dots: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <>
      <section style={{ backgroundColor: "#FAF8F6" }} className="p mt-5">
        <div className="container mt-4 pt-4 mb-4">
          <h2 className="fw-bold text-center" style={{ color: "var(--orange" }}>
            Students review
          </h2>
          <div className="row mt-4">
            <OwlCarousel className="owl-theme" {...options}>
              {ReviewData.map((val) => {
                return (
                  <>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <Reviews img={val.img} des={val.des} name={val.name} />
                    </div>
                  </>
                );
              })}
            </OwlCarousel>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
