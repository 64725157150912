import React from "react";
import logo from "../Assets/images/takechange.png";
import socialIcons from "../Assets/images/Socialicons.png";

const Footer = () => {
  return (
    <>
      <section className="py-4 pt-5 " style={{ backgroundColor: "#FAF8F6" }}>
        <div className="container">
          <div className="row pb-5">
            <div className="col-md-4">
              <div>
                <img src={logo} alt="" />
              </div>
              <div className="mt-3">
                <p
                  style={{
                    color: "#4A556C",
                    textAlign: "justify",
                    fontSize: "14px",
                  }}
                >
                  Design amazing digital experiences that
                  <br />
                  create more happy in the world.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <h5 className="fw-bold">Pages</h5>
                <ul className="mt-3" style={{ padding: "0px", margin: "0px" }}>
                  <li className="my-2" style={{ listStyleType: "none" }}>
                    <a
                      href=""
                      style={{
                        textDecoration: "none",
                        color: "#4A556C",
                        fontSize: "14px",
                      }}
                    >
                      Home{" "}
                    </a>
                  </li>
                  <li style={{ listStyleType: "none" }} className="my-2">
                    <a
                      href=""
                      style={{
                        textDecoration: "none",
                        color: "#4A556C",
                        fontSize: "14px",
                      }}
                    >
                      About us
                    </a>
                  </li>

                  <li style={{ listStyleType: "none" }} className="my-2">
                    <a
                      href=""
                      style={{
                        textDecoration: "none",
                        color: "#4A556C",
                        fontSize: "14px",
                      }}
                    >
                      Our Courses
                    </a>
                  </li>

                  <li style={{ listStyleType: "none" }} className="my-2">
                    <a
                      href=""
                      style={{
                        textDecoration: "none",
                        color: "#4A556C",
                        fontSize: "14px",
                      }}
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <h5 className="fw-bold">Contact</h5>
                <ul className="mt-3" style={{ padding: "0px", margin: "0px" }}>
                  <li className="my-2" style={{ listStyleType: "none" }}>
                    <a
                      href=""
                      style={{
                        textDecoration: "none",
                        color: "#4A556C",
                        fontSize: "14px",
                      }}
                    >
                      +123 456 789
                    </a>
                  </li>
                  <li style={{ listStyleType: "none" }} className="my-2">
                    <a
                      href=""
                      style={{
                        textDecoration: "none",
                        color: "#4A556C",
                        fontSize: "14px",
                      }}
                    >
                      hello@happydigital.nl
                    </a>
                  </li>

                  <li style={{ listStyleType: "none" }} className="my-2">
                    <a
                      href=""
                      style={{
                        textDecoration: "none",
                        color: "#4A556C",
                        fontSize: "14px",
                      }}
                    >
                      Instagram
                    </a>
                  </li>

                  <li style={{ listStyleType: "none" }} className="my-2">
                    <a
                      href=""
                      style={{
                        textDecoration: "none",
                        color: "#4A556C",
                        fontSize: "14px",
                      }}
                    >
                      LinkedIn
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-6">
              <p>Made with a by HappyDigital</p>
            </div>
            <div className="col-md-6">
              <img src={socialIcons} className="float-end" alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
