import React from "react";
import style from "../About/AboutHead.module.css";

const AboutHead = ({ title, img, des }) => {
  return (
    <section style={{ backgroundColor: "#" }}>
      <div className="container">
        <div className="row pt-5 pb-5 ">
          <div className="col-md-7 col-lg-6 order-2 order-md-1 mt-2">
            <div className="">
              <h1 className="fw-bold" style={{ color: "var(--orange" }}>
                Welcome to{" "}
                <span style={{ color: "var(--blue)" }}> TakeChange</span> –
                where coding aspirations meet transformation!
              </h1>
              <p
                className="mt-4 fs-5"
                style={{ color: "#4A556C", textAlign: "justify" }}
              >
                {des}
              </p>
            </div>
          </div>
          <div className="col-md-5 col-lg-6 order-1 order-md-2">
            <div className="mt-3 ">
              <img src={img} className="img-fluid mx-auto d-block" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutHead;
