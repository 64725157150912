import React from "react";
import CourseDetailsCards from "./CourseDetailsCards";
import { useParams } from "react-router-dom";
import java from "../../Assets/images/Java.png";
import python from "../../Assets/images/python.png";
import php from "../../Assets/images/php.png";

const CoursesDetails = () => {
  let { id } = useParams();
  const introCard = [
    {
      id: "1",
      icon: python,
      title: "Python FullStack",
      desc: "This course will educate you on the main basics of Python, Conditional Statements, Data Operations, Django & also Shell Scripting. Best Online Python Course will also give you real hand experience on live projects and prepare you for a fantastic career as a professional Python programmer.",
      list: [
        "HTML & CSS3 Syllabus",
        "Bootstrap Syllabus",
        "Javascript Syllabus",
        "PostgreSQL Syllabus",
        "Vue JSSyllabus",
        "Django Syllabus",
      ],
    },
    {
      id: "2",
      icon: java,
      title: "Java FullStack",
      desc: "If you’re looking Best Online and Offline Java course & training who make you master of web application development for any online computing platform, so this course is only for you. This ultimate training & course will give you a strong foundation in Java Programming language which widely used in software development.",
      list: [
        "JAVA Full Stack",
        "HTML & CSS",
        "Javascript",
        "Web architecture",
        "HTTP and REST",
        "Spring MVC Testing",
      ],
    },
    {
      id: "3",
      icon: php,
      title: "PHP FullStack",
      desc: "If you’re looking Best Online and Offline PHP course & training who make you master of web application development for any online computing platform, so this course is only for you. This ultimate training & course will give you a strong foundation in PHP Programming language which widely used in software development.",
      list: ["HTML & CSS", "Javascript", "PHP", "Database"],
    },
    {
      id: "4",
      icon: java,
      title: "Java FullStack",
      list: [
        "JAVA Full Stack",
        "HTML & CSS",
        "Javascript",
        "Web architecture",
        "HTTP and REST",
        "Spring MVC Testing",
      ],
    },
  ];
  const filteredCard = introCard.filter((card) => card.id === id);
  return (
    <>
      <div className="container">
        <div className="row">
          {filteredCard.length > 0 ? (
            <div className="row mt-3">
              {filteredCard.map((val) => (
                <div key={val.id} className=" col-md-12 col-lg-12">
                  <CourseDetailsCards
                    id={val.id}
                    title={val.title}
                    desc={val.desc}
                    list={val.list}
                    icon={val.icon}
                  />
                </div>
              ))}
            </div>
          ) : (
            <p>No course found with ID {id}</p>
          )}
        </div>
      </div>
    </>
  );
};

export default CoursesDetails;
