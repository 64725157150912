import React from "react";
import style from "../About/AboutHead2.module.css";

const AboutHead2 = ({ title, img, des }) => {
  return (
    <section style={{}}>
      <div className="container">
        <div className="row pt-5 pb-5">
          <div className="col-md-6">
            <div className="mt-4">
              <img
                src={img}
                className="img-fluid mx-auto d-block "
                alt=""
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <div>
              <p className="text-muted fs-4">Who we are</p>
              <h2 className="fw-bold" style={{ color: "var(--orange)" }}>
                We Are <span style={{ color: "var(--blue)" }}> TakeChange</span>{" "}
                Solutions
              </h2>
              <p
                className="mt-3 mb-5 fs-5"
                style={{ color: "#4A556C", textAlign: "justify" }}
              >
                {des}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutHead2;
