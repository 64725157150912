import React from "react";
import AboutHead from "../components/About/AboutHead";
import about1 from "../Assets/images/about_01.jpg";
import about2 from "../Assets/images/about_02.jpg";

import head from "../Assets/images/bajaj.png";
import AboutHead2 from "../components/About/AboutHead2";
const About = () => {
  const aboutHead = [
    {
      title:
        "Welcome to TakeChange – where coding aspirations meet transformation!",
      des: "At TakeChange, we believe in the power of coding to shape the future, and we're here to guide you on your journey to programming mastery. Our passion lies in empowering individuals to embrace change, take charge of their learning, and become proficient in the ever-evolving world of programming languages.",
      img: about1,
    },
  ];
  const aboutHead2 = [
    {
      title: "We Are TakeChange Solutions",
      des: "Innovation in Learning: At TakeChange, we've redefined the learning experience. Our coaching approach is innovative, dynamic, and tailored to meet the unique needs of each learner. We're not just teaching code; we're fostering a mindset of continuous improvement. Expert Guidance: Our team comprises seasoned coding professionals who are dedicated to sharing their wealth of knowledge and industry insights. TakeChange takes pride in offering personalized coaching, ensuring that you receive the attention and guidance you need to thrive in the coding landscape.",
      img: about2,
    },
  ];
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="var(--blue)"
          fill-opacity="0.3"
          d="M0,256L48,261.3C96,267,192,277,288,261.3C384,245,480,203,576,197.3C672,192,768,224,864,213.3C960,203,1056,149,1152,154.7C1248,160,1344,224,1392,256L1440,288L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>

      {aboutHead.map((val) => {
        return (
          <>
            <AboutHead title={val.title} des={val.des} img={val.img} />;
          </>
        );
      })}
      {aboutHead2.map((val) => {
        return (
          <>
            <AboutHead2 title={val.title} des={val.des} img={val.img} />;
          </>
        );
      })}
    </>
  );
};

export default About;
