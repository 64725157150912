import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import hero2 from "../Assets/images/hero2.jpg";
import { useNavigate } from "react-router-dom";

const Hero = (props) => {
  useEffect(() => {
    AOS.init();
  }, []);

  const { title, des, img } = props;
  const navigate = useNavigate();

  const handleContact = () => {
    navigate("contact");
  };

  const handleCourses = () => {
    navigate("courses");
  };

  return (
    <section
      className="hero-section "
      style={{
        backgroundImage: `url(${hero2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
      }}
    >
      <div className="container">
        <div className="row pt-3">
          <div
            className="col-lg-6 col-md-12 col-sm-12 mt-5"
            data-aos="fade-right"
            data-aos-easing="linear"
            data-aos-duration="1500"
          >
            <div className="mt-3">
              <h2 className="fw-bold" style={{ color: "var(--orange)" }}>
                Welcome to{" "}
                <span style={{ color: "var(--blue)" }}>TakeChange</span> – where
                coding aspirations meet transformation!
              </h2>
              <div>
                <p
                  className="mt-4 mb-5 fs-5"
                  style={{ color: "white", textAlign: "justify" }}
                >
                  {des}
                </p>
              </div>
              <div className="mb-4">
                <button
                  className="btn text-white"
                  onClick={handleContact}
                  style={{ backgroundColor: "var(--blue)" }}
                >
                  Contact Now
                </button>
                <button
                  onClick={handleCourses}
                  className="btn text-white ms-2"
                  style={{ backgroundColor: "var(--blue)" }}
                >
                  Our Courses
                </button>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 mt-4 col-md-12 col-sm-12"
            data-aos="fade-left"
            data-aos-easing="linear"
            data-aos-duration="1500"
          >
            <div
              className="mt-3 shadow-lg p- mt-5 mb-5 bg-body rounded"
              style={{
                border: "2px solid var()",
              }}
            >
              <img
                src={img}
                className="img-fluid mx-auto d-block"
                alt=""
                style={{ maxWidth: "100%", height: "auto" }}
              />
              {/* <span className="position-absolute translate-middle top-0  bg-white py-3 px-3">
                  <img
                    src={hero2}
                    className="img-fluid"
                    style={{ width: "100px" }}
                    alt=""
                  />
                </span> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
