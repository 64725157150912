import React from "react";
import JsonVideo from "../../Data/JsonData.json";
import Video from "./Video";
import "../ReviewVideo/ReviewVideo.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const ReviewVideo = () => {
  const options = {
    loop: true,
    center: true,
    items: 3,
    margin: 0,
    autoplay: true,
    dots: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <>
      <section style={{ backgroundColor: "" }} className=" ">
        <div className="container  pb-4 mb-5">
          <h3 className="fw-bold text-center" style={{ color: "var(--orange" }}>
            Hear out what our Students say about Us.
          </h3>
          <div className="row">
            <div className="col-md-12">
              <OwlCarousel
                id="customer-testimonoals"
                className="owl-carousel owl-theme"
                {...options}
              >
                {JsonVideo.map((val) => {
                  return <Video video={val.video} />;
                })}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReviewVideo;
