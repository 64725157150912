import React from "react";
import style from "../HomeCourses/IntroCards.module.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
const IntroCards = (props) => {
  const { id, icon, title, list } = props;
  // const navigate = useNavigate();
  // const handleReadMore = () => {
  //   navigate(`courses#${id}`);
  // };
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div
        data-aos="fade-up"
        data-aos-duration="3000"
        class={`card mt-2 h-100 mt-3 shadow pt-2 mb bg-body-tertiary rounded ${style.courseCard}`}
        style={{ border: "none" }}
      >
        <img
          src={icon}
          className="w-25 h-25 mx-auto d-block mt-3 card-img-top img-fluid"
          alt=""
        />

        <div class="card-body mt-3">
          <h5 class={`card-title fw-bold p-2 ${style.h5}`}>{title}</h5>
          <ul
            class="list-group list-group-flush ms-4 "
            style={{
              color: "#4A556C",
              textAlign: "justify",
              fontSize: "15px",
              listStyleType: "square",
            }}
          >
            {list.map((item) => {
              return (
                <>
                  <li className="list"> {item}</li>
                </>
              );
            })}
          </ul>
          {/* <p>kgkgkg</p> */}

          <button className={`${style.button} p-1 rounded-1 mt-2 `}>
            <Link className={`${style.li} m-2`} to={`/singleCourse/${id}`}>
              Read More
            </Link>
          </button>
        </div>
      </div>
    </>
  );
};

export default IntroCards;
