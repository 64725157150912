import React from "react";
import Home from "./pages/Home";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Layout from "./components/Layout";
import About from "./pages/About";
import Courses from "./pages/Courses";
import Contact from "./pages/Contact";
import SingleCourse from "./pages/SingleCourse";
import EnrollNow from "./components/enrollForm/EnrollNow";
const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout />}>
        <Route path="" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="courses" element={<Courses />} />
        <Route path="contact" element={<Contact />} />
        <Route path="singleCourse/:id" element={<SingleCourse />} />
        <Route path="enroll" element={<EnrollNow />} />
      </Route>
    )
  );
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export default App;
