import React from "react";

const ContactForm = () => {
  return (
    <>
      <section className="mb-5">
        <div className="container">
          <h2 class="text-center">Contact Us</h2>
          <div className="row">
            <div className="col-md-12">
              <form
                action=""
                className="w-75 mx-auto mt-4 p-5 border border-3 bg-light"
              >
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="text"
                      placeholder="Enter Your Name"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Number"
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <input
                      type="email"
                      className="form-control "
                      placeholder="Enter Your Email"
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <div class="form-floating">
                      <textarea
                        class="form-control"
                        placeholder="Leave a comment here"
                        id="floatingTextarea2"
                        style={{ height: " 100px" }}
                      ></textarea>
                      <label for="floatingTextarea2">Comments</label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
