import React, { useState, useEffect } from "react";
import Logo from "../../Assets/images/class_logo.png";
import Logo2 from "../../Assets/images/takechange.png";

import { NavLink, Link } from "react-router-dom";
import style from "../Navbar/Header.module.css";
const Header = () => {
  const [navbarColor, setNavbarColor] = useState("white");
  const handleScroll = (event) => {
    let scroll = window.scrollY;
    if (scroll > 0) {
      setNavbarColor("transparent");
    } else {
      setNavbarColor("transparent");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div
        className="container fixed-top   "
        onScroll={handleScroll}
        style={{ backgroundColor: navbarColor }}
      >
        <nav
          class="navbar navbar-expand-lg   "
          style={{ background: "transparent", backdropFilter: "blur(10px)" }}
        >
          <div class="container-fluid">
            <a class="navbar-brand" href="#">
              <img src={Logo2} className="w-75" alt="" />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse " id="navbarNav">
              <ul class="navbar-nav mx-auto ">
                <li class={`nav-item  mx-2 `}>
                  <NavLink
                    to=""
                    className={({ isActive }) =>
                      `nav-link ${isActive ? style.visit : style.li} `
                    }
                  >
                    Home
                  </NavLink>
                </li>
                <li class={`nav-item  mx-2 `}>
                  <NavLink
                    to="about"
                    className={({ isActive }) =>
                      `nav-link ${isActive ? style.visit : style.li} `
                    }
                  >
                    About us
                  </NavLink>
                </li>

                <li class={`nav-item  mx-2 `}>
                  <NavLink
                    to="courses"
                    className={({ isActive }) =>
                      `nav-link ${isActive ? style.visit : style.li} `
                    }
                  >
                    Our Courses
                  </NavLink>
                </li>
                <li class={`nav-item  mx-2 `}>
                  <NavLink
                    to="contact"
                    className={({ isActive }) =>
                      `nav-link ${isActive ? style.visit : style.li} `
                    }
                  >
                    Contact Us
                  </NavLink>
                </li>
                <li class={`nav-item  mx-2 `}>
                  <button className={`btn  ${style.enrollButton}`}>
                    <NavLink
                      to="enroll"
                      className={` fw-bold ${style.enrollLink}`}
                    >
                      Register Now
                    </NavLink>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
