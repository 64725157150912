import React from "react";

const EnrollNow = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="var(--blue)"
          fillOpacity="0.3"
          d="M0,256L48,261.3C96,267,192,277,288,261.3C384,245,480,203,576,197.3C672,192,768,224,864,213.3C960,203,1056,149,1152,154.7C1248,160,1344,224,1392,256L1440,288L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>
      <section>
        <div className="container">
          <div className="border border-2 w-75 mx-auto p-5 mb-5">
            <form>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="fullName" className="form-label">
                    Full Name
                  </label>
                  <input type="text" className="form-control" id="fullName" />
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input type="email" className="form-control" id="email" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mb-3">
                  <label htmlFor="phoneNumber" className="form-label">
                    Phone Number
                  </label>
                  <input type="tel" className="form-control" id="phoneNumber" />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <select className="form-select" id="city">
                    <option value="">Select</option>
                    <option value="Nashik">Nashik</option>
                    <option value="Mumbai">Mumbai</option>
                    <option value="Pune">Pune</option>
                  </select>
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="dob" className="form-label">
                    Date of Birth
                  </label>
                  <input type="date" className="form-control" id="dob" />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-4">
                  <span className="form-label">Current Educational Status</span>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="educationStatus"
                      id="undergraduate"
                    />
                    <label className="form-check-label" htmlFor="undergraduate">
                      Undergraduate
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="educationStatus"
                      id="graduate"
                    />
                    <label className="form-check-label" htmlFor="graduate">
                      Graduate
                    </label>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="course" className="form-label">
                    Choose the Course
                  </label>
                  <select className="form-select" id="course">
                    <option value="">Select</option>
                    <option value="Java">JAVA FULL STACK</option>
                    <option value="Python">PYTHON FULL STACK</option>
                    <option value="Php">PHP FULL STACK</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <span className="form-label">Mode Of Course</span>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="courseMode"
                      id="offline"
                    />
                    <label className="form-check-label" htmlFor="offline">
                      Offline
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="courseMode"
                      id="online"
                    />
                    <label className="form-check-label" htmlFor="online">
                      Online
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <button
                    type="submit"
                    className="btn btn-outline-primary w-100"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default EnrollNow;
