import React from "react";
import style from "../Testimonials/Reviews.module.css";

const Reviews = ({ img, des, icon, name }) => {
  return (
    <div
      className={`card p-3 shadow mb-5 mx-3 mt-3 bg-body-tertiary rounded ${style.color}`}
      style={{ border: "none" }}
    >
      <div className="card-body">
        <img
          src={img}
          className="img-fluid mb-3 mx-auto d-block"
          style={{ maxWidth: "100px", height: "auto" }}
          alt=""
        />
        <p
          className="card-text"
          style={{ color: "#4A556C", textAlign: "justify", fontSize: "15px" }}
        >
          {des}
        </p>
        <h5 className="card-title fw-bold">{name}</h5>
      </div>
    </div>
  );
};

export default Reviews;
