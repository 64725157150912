import React from "react";
import style from "../HomeCourses/IntroCards.module.css";
const CourseDetailsCards = ({ id, icon, title, list, desc }) => {
  return (
    <>
      <div class="card mb-3 mt-3 border border-1 p-4" style={{}}>
        <div class="row g-0">
          <div class="col-md-4 ">
            <img
              src={icon}
              class="img-fluid w-50 h-75 rounded-start mx-auto d-block"
              alt="..."
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">{title}</h5>
              <p class="card-text">{desc}</p>
              <ul
                class="list-group list-group-flush ms-4 "
                style={{
                  color: "#4A556C",
                  textAlign: "justify",
                  fontSize: "15px",
                  listStyleType: "square",
                }}
              >
                {list.map((item) => {
                  return (
                    <>
                      <li className="list"> {item}</li>
                    </>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseDetailsCards;
